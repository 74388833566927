import { captureException } from '@sentry/react';
import { useMutation, useQuery } from '@wirechunk/apollo-client';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { FormComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { PreviewFormObject } from '@wirechunk/lib/visual-preview.ts';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import { isBoolean, isEmpty, isNumber, isString } from 'lodash-es';
import type { FormEventHandler, FunctionComponent } from 'react';
import { use, useCallback, useMemo, useState } from 'react';
import { FormContext } from '../../../contexts/FormContext/form-context.tsx';
import { InputDataContext, useInputDataContextValue } from '../../../contexts/InputDataContext.tsx';
import { PageContext, ViewMode } from '../../../contexts/page-context.tsx';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import type { ErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import {
  triggerFacebookEvent,
  triggerGoogleTagManagerEvent,
  triggerTikTokEvent,
} from '../../../util/analyticsScripts.ts';
import { tryParseObject } from '../../../util/json.ts';
import { ParseAndRenderComponents } from '../../ParseAndRenderComponents.tsx';
import { RenderComponentsStyled } from '../../RenderComponentsStyled.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { defaultConfirmationMessageComponents } from './default-confirmation-message-components.ts';
import { SubmitFormDocument } from './mutations.generated.ts';
import type { PublicSiteFormQuery } from './queries.generated.ts';
import {
  PublicSiteFormDocument,
  PreviewFormTemplateDocument,
  InitialFormDataDocument,
} from './queries.generated.ts';
import { ConfirmationAction } from '#api';

type ScopedFormProps = Omit<FormComponent, 'formId'>;

const TemplateScopeForm: FunctionComponent<ScopedFormProps & { formTemplateId: string }> = (
  props,
) => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(PreviewFormTemplateDocument, {
    onError,
    variables: { id: props.formTemplateId },
  });

  if (loading) {
    return <Spinner py="3" />;
  }

  if (!data) {
    return <ErrorMessage />;
  }

  return (
    <FormBody
      {...props}
      formId={props.formTemplateId}
      form={data.formTemplate}
      onError={onError}
      clearMessages={clearMessages}
      ErrorMessage={ErrorMessage}
    />
  );
};

type PageScopeFormProps = ScopedFormProps & {
  formId: string;
};

const PageScopeForm: FunctionComponent<PageScopeFormProps> = (props) => {
  const site = useSiteContext();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data, loading } = useQuery(PublicSiteFormDocument, {
    onError,
    variables: {
      siteId: site.id,
      formId: props.formId,
    },
  });
  const { data: initialFormData, loading: initialFormDataLoading } = useQuery(
    InitialFormDataDocument,
    {
      onError,
      variables: {
        formId: props.formId,
        pageUrl: window.location.href,
      },
      // Always get fresh data on any new render of a form.
      fetchPolicy: 'no-cache',
    },
  );
  const parsedInitialFormData = useMemo<ContextData>(
    () =>
      initialFormData?.initialFormData.formData
        ? (tryParseObject(initialFormData.initialFormData.formData) as ContextData)
        : {},
    [initialFormData?.initialFormData.formData],
  );

  if (loading || initialFormDataLoading) {
    return <Spinner py="3" />;
  }

  if (!data) {
    return <ErrorMessage />;
  }

  return (
    <FormBody
      {...props}
      form={data.publicSite.form}
      initialFormData={parsedInitialFormData}
      onError={onError}
      clearMessages={clearMessages}
      ErrorMessage={ErrorMessage}
    />
  );
};

type SiteForm = PublicSiteFormQuery['publicSite']['form'];

type FormBodyForm = {
  id: string;
  components: SiteForm['components'];
  confirmationAction: SiteForm['confirmationAction'];
  confirmationMessageComponents?: SiteForm['confirmationMessageComponents'] | null;
  confirmationRedirectUrl?: SiteForm['confirmationRedirectUrl'] | null;
  confirmationRedirectParameters: SiteForm['confirmationRedirectParameters'];
  steps: Array<{ id: string; components: string }>;
};

type FormBodyProps = FormComponent & {
  form: FormBodyForm | PreviewFormObject;
  initialFormData?: ContextData;
  onError: ErrorHandler['onError'];
  clearMessages: ErrorHandler['clearMessages'];
  ErrorMessage: ErrorHandler['ErrorMessage'];
};

export const FormBody: FunctionComponent<FormBodyProps> = ({ form, clearMessages, ...props }) => {
  const {
    steps,
    confirmationAction,
    confirmationMessageComponents,
    confirmationRedirectUrl,
    confirmationRedirectParameters,
  } = form;
  const inputDataContextValue = useInputDataContextValue(props.initialFormData);
  const [currentStep, setCurrentStep] = useState(steps[0] || null);
  const [sessionId, setSessionId] = useState<string | null>(null);

  const [submitted, setSubmitted] = useState(false);
  const [submitForm, { loading: submitting }] = useMutation(SubmitFormDocument, {
    onError: props.onError,
  });
  const { viewMode } = use(PageContext);

  const handleSubmit = useCallback<FormEventHandler>(
    (evt) => {
      evt.preventDefault();
      if (submitted || viewMode === ViewMode.Preview) {
        return;
      }

      clearMessages();

      const validationErrors = inputDataContextValue.validate();
      if (!isEmpty(validationErrors)) {
        return;
      }

      void (async () => {
        try {
          // Trigger conversion events. These are asynchronous.
          triggerTikTokEvent('Contact', { content_id: form.id });
          triggerFacebookEvent('track', 'Lead', { content_ids: [form.id] });
          triggerGoogleTagManagerEvent({ event: 'form_submission', form_id: form.id });

          await submitForm({
            variables: {
              formId: form.id,
              stepId: currentStep?.id,
              sessionId,
              formData: JSON.stringify(inputDataContextValue.data.visible),
              pageUrl: window.location.href,
            },
            onCompleted: ({ submitForm }) => {
              setSessionId(submitForm.sessionId);
              if (!steps.length || !currentStep || steps.at(-1)?.id === currentStep.id) {
                setSubmitted(true);
                if (confirmationAction === ConfirmationAction.Redirect && confirmationRedirectUrl) {
                  try {
                    const redirectUrl = confirmationRedirectUrl.startsWith('/')
                      ? new URL(confirmationRedirectUrl, window.location.origin)
                      : new URL(confirmationRedirectUrl);

                    const setParam = (paramName: string, value: unknown) => {
                      if (isString(value) || isNumber(value) || isBoolean(value)) {
                        redirectUrl.searchParams.append(paramName, value.toString());
                      }
                    };

                    for (const { parameter, componentName } of confirmationRedirectParameters) {
                      const paramValue = inputDataContextValue.data.visible[componentName];
                      if (Array.isArray(paramValue)) {
                        for (const value of paramValue) {
                          setParam(parameter, value);
                        }
                      } else {
                        setParam(parameter, paramValue);
                      }
                    }

                    window.location.href = redirectUrl.toString();
                  } catch (err) {
                    // Not handled for now.
                    console.error(err);
                  }
                }
              } else if (steps.length) {
                setCurrentStep(
                  (currentStep) =>
                    steps[steps.findIndex(({ id }) => currentStep && id === currentStep.id) + 1] ||
                    null,
                );
              }
            },
          });
        } catch (err) {
          // This should never happen because the mutation has "onError" defined.
          captureException(err);
        }
      })();
    },
    [
      submitted,
      viewMode,
      clearMessages,
      inputDataContextValue,
      form.id,
      submitForm,
      currentStep,
      sessionId,
      steps,
      confirmationAction,
      confirmationRedirectUrl,
      confirmationRedirectParameters,
    ],
  );

  const formContext = useMemo<FormContext>(
    () => ({
      id: form.id,
      currentStep,
      confirmationMessageComponents,
      submitting,
      submitted,
      goBackFormStep: () => {
        setCurrentStep(
          (currentStep) =>
            currentStep && (steps[steps.findIndex(({ id }) => id === currentStep.id) - 1] || null),
        );
      },
    }),
    [form.id, currentStep, confirmationMessageComponents, submitting, submitted, steps],
  );

  if (submitted && confirmationAction !== ConfirmationAction.MessageKeepForm) {
    if (confirmationAction === ConfirmationAction.Message) {
      return confirmationMessageComponents && confirmationMessageComponents !== '[]' ? (
        Array.isArray(confirmationMessageComponents) ? (
          <RenderComponentsStyled components={confirmationMessageComponents} />
        ) : (
          <ParseAndRenderComponents componentsJSON={confirmationMessageComponents} />
        )
      ) : (
        <RenderComponentsStyled components={defaultConfirmationMessageComponents} />
      );
    }
    return null;
  }

  return (
    <form className={componentClassName(props)} onSubmit={handleSubmit}>
      <props.ErrorMessage />
      <FormContext value={formContext}>
        <InputDataContext value={inputDataContextValue}>
          {Array.isArray(form.components) ? (
            <RenderComponentsStyled components={form.components} />
          ) : (
            <ParseAndRenderComponents componentsJSON={form.components} />
          )}
        </InputDataContext>
      </FormContext>
    </form>
  );
};

export const Form: FunctionComponent<FormComponent> = (props) => {
  const pageContext = use(PageContext);

  if (props.formId) {
    return pageContext.viewMode !== ViewMode.Preview ? (
      <PageScopeForm {...props} formId={props.formId} />
    ) : (
      <TemplateScopeForm {...props} formTemplateId={props.formId} />
    );
  }

  return null;
};
