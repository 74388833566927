import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import { createContext, use } from 'react';

export type FormContext = {
  id: string;
  currentStep: {
    id: string;
    components: string | Component[];
  } | null;
  confirmationMessageComponents: string | Component[] | null | undefined;
  submitting: boolean;
  submitted: boolean;
  goBackFormStep: () => void;
};

export const FormContext = createContext<FormContext | null>(null);

export const FormContextProvider = FormContext.Provider;

export const useFormContext = (): FormContext | null => use(FormContext);
