import { type Value, DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import { use } from 'react';
import { PageContext, ViewMode } from '../../contexts/page-context.tsx';
import { PropsContext } from '../../contexts/props-context.ts';
import { SiteContext } from '../../contexts/SiteContext/SiteContext.tsx';

export const useStringValue = ({ value }: Value): string | null => {
  if (!value) {
    return null;
  }

  if (value.type === DataSource.Prop) {
    const propsContext = use(PropsContext);
    return value.name ? stringOrDefaultNull(propsContext[value.name]) : null;
  }

  if (value.type === 'PageTitle') {
    const pageContext = use(PageContext);
    if (pageContext.viewMode === ViewMode.NotFound) {
      return null;
    }
    return pageContext.page.title;
  }

  if (value.type === 'SiteName') {
    const siteContext = use(SiteContext);
    return siteContext.name;
  }

  return stringOrDefaultNull(value.value);
};
