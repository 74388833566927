import { useMutation, useQuery } from '@wirechunk/apollo-client';
import type { ResetPasswordComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import type { FunctionComponent } from 'react';
import { use, Fragment, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { CurrentUserContext } from '../../../contexts/current-user-context.tsx';
import { SiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { WirechunkLogo } from '../../admin/wirechunk-logo.tsx';
import { FormField } from '../../form-field/form-field.tsx';
import { Label } from '../../label/label.tsx';
import { SiteLogoStandardStyle } from '../../site-logo-standard-style.tsx';
import { ResetPasswordDocument } from './mutations.generated.ts';
import { PasswordResetInfoDocument } from './queries.generated.ts';

const { admin } = window.wirechunk;

export const ResetPassword: FunctionComponent<ResetPasswordComponent> = (props) => {
  const siteContext = use(SiteContext);
  // Tempoary hack - SiteContext is not defined on the admin site.
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const siteName = siteContext?.name ?? 'Wirechunk';
  const [params] = useSearchParams();
  const key = params.get('key');
  const mode = props.mode || 'reset';
  const { user } = use(CurrentUserContext);
  const navigate = useNavigate();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { data: resetInfoData, loading } = useQuery(PasswordResetInfoDocument, {
    onError,
    ...(user || !key ? { skip: true } : { variables: { key } }),
  });
  const [resetPassword, { loading: isResetting }] = useMutation(ResetPasswordDocument, {
    onError,
    onCompleted: () => {
      // Do a hard redirect to the home page to force the Apollo Client state to reset. Imperatively resetting the
      // state did not work due to a data race, and we needed to coordinate the navigation with the reset, which
      // was tricky.
      window.location.href = '/';
    },
  });
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const onSubmit = () => {
    clearMessages();
    if (password !== password2) {
      onError('The passwords you entered do not match.');
      return;
    }
    if (key) {
      void resetPassword({
        variables: { key, password },
      });
    }
  };

  return (
    <div className="h-screen flex justify-content-center align-items-center surface-ground">
      <div className="w-22rem max-body-width-contained bg-white p-4 border-1 border-round mb-2">
        <div className="flex align-items-center justify-content-center gap-2 mb-4">
          {admin ? <WirechunkLogo /> : <SiteLogoStandardStyle />}
        </div>
        <ErrorMessage />
        {user ? (
          <div className="text-center">
            You are already signed in.
            <br />({user.email})
          </div>
        ) : isResetting || loading ? (
          <div className="text-center">
            {isResetting && (
              <div className="font-medium mb-3">Setting your password now&hellip;</div>
            )}
            <i className="pi pi-spinner pi-spin text-xl text-color-primary" />
          </div>
        ) : resetInfoData ? (
          <Fragment>
            <p>
              <span className="font-medium">{resetInfoData.passwordResetInfo.name}</span>, you can{' '}
              {mode === 'create' ? 'create' : 'change'} your password now&hellip;
            </p>
            <FormField>
              <Label htmlFor="newPassword1" className="text-left">
                Your new password
              </Label>
              <InputText
                id="newPassword1"
                className="w-full"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </FormField>
            <FormField>
              <Label htmlFor="newPassword2" className="text-left">
                Confirm your new password
              </Label>
              <InputText
                id="newPassword2"
                className="w-full"
                type="password"
                value={password2}
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            </FormField>
            <Button
              className="mt-2 w-full"
              label={mode === 'create' ? 'Set password' : 'Reset password'}
              disabled={!password || isResetting}
              onClick={onSubmit}
            />
          </Fragment>
        ) : (
          <div className="text-center">
            <div className="flex flex-column gap-3 text-center mt-3">
              <Button
                label="Reset password"
                onClick={() => {
                  void navigate('/reset-password/request');
                }}
              />
              <Button
                label={`Go to ${siteName}`}
                onClick={() => {
                  void navigate('/');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
