import { createContext, use } from 'react';

export type LessonUserContext = {
  notes: string;
  setNotes: (notes: string) => void;
};

export const LessonUserContext = createContext<LessonUserContext | null>(null);

export const LessonUserContextProvider = LessonUserContext.Provider;

export const useLessonUserContext = (): LessonUserContext | null => use(LessonUserContext);
