import type { FormConfirmationMessageComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useFormContext } from '../../contexts/FormContext/form-context.tsx';
import { ParseAndRenderComponents } from '../ParseAndRenderComponents.tsx';
import { RenderComponentsStyled } from '../RenderComponentsStyled.tsx';
import { defaultConfirmationMessageComponents } from './form/default-confirmation-message-components.ts';

export const FormConfirmationMessage: FunctionComponent<FormConfirmationMessageComponent> = () => {
  const formContext = useFormContext();
  if (!formContext?.submitted) {
    return null;
  }
  const cs = formContext.confirmationMessageComponents;
  if (!cs || cs === '[]' || cs.length === 0) {
    return <RenderComponentsStyled components={defaultConfirmationMessageComponents} />;
  }
  if (Array.isArray(cs)) {
    return <RenderComponentsStyled components={cs} />;
  }
  return <ParseAndRenderComponents componentsJSON={cs} />;
};
