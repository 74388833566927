import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import type { ComponentType, FunctionComponent } from 'react';
import { lazy } from 'react';
import { ActiveOrgSitePageGuard } from './mixer/active-org-site-page-guard/active-org-site-page-guard.tsx';
import { AgreementsGuard } from './mixer/agreements-guard/agreements-guard.tsx';
import { BooleanInput } from './mixer/BooleanInput/BooleanInput.tsx';
import { Box } from './mixer/Box.tsx';
import { Button } from './mixer/Button.tsx';
import { CollapsiblePanel } from './mixer/CollapsiblePanel.tsx';
import { CompletableVideoInput } from './mixer/CompletableVideoInput/CompletableVideoInput.tsx';
import { ConditionalLogic } from './mixer/conditional-logic/conditional-logic.tsx';
import { CurrentYear } from './mixer/current-year.tsx';
import { CustomComponent } from './mixer/custom-component/custom-component.tsx';
import { Dashboard } from './mixer/dashboard.tsx';
import { DataInputTable } from './mixer/DataInputTable/DataInputTable.tsx';
import { DateInput } from './mixer/DateInput/DateInput.tsx';
import { Document } from './mixer/document/document.tsx';
import { DropdownInput } from './mixer/DropdownInput/DropdownInput.tsx';
import { Form } from './mixer/form/Form.tsx';
import { FormConfirmationMessage } from './mixer/form-confirmation-message.tsx';
import { FormStep } from './mixer/form-step/form-step.tsx';
import { HTML } from './mixer/HTML.tsx';
import { Icon } from './mixer/Icon.tsx';
import { Image } from './mixer/image.tsx';
import { InputValidationErrorMessage } from './mixer/input-validation-error-message/input-validation-error-message.tsx';
import { LessonNotesField } from './mixer/lesson-notes-field/lesson-notes-field.tsx';
import { Link } from './mixer/link.tsx';
import { Map } from './mixer/map.tsx';
import { MoveSequenceUser } from './mixer/MoveUserPlan/move-sequence-user.tsx';
import { QuizMultipleChoiceInput } from './mixer/MultipleChoiceInput/quiz-multiple-choice-input.tsx';
import { QuizMultiSelectInput } from './mixer/MultiSelectInput/quiz-multi-select-input.tsx';
import { NumberInput } from './mixer/NumberInput/NumberInput.tsx';
import { OrgSitePageLink } from './mixer/org-site-page-link/org-site-page-link.tsx';
import { OrganizationSiteLeads } from './mixer/OrganizationSiteLeads/OrganizationSiteLeads.tsx';
import { Padding } from './mixer/padding/padding.tsx';
import { Page } from './mixer/page.tsx';
import { PhoneLink } from './mixer/phone-link.tsx';
import { PlainText } from './mixer/plain-text.tsx';
import { Popover } from './mixer/Popover/popover.tsx';
import { ProvideProps } from './mixer/provide-props/provide-props.tsx';
import { RadioGroupInput } from './mixer/radio-group-input/radio-group-input.tsx';
import { RadioGroupInputButton } from './mixer/radio-group-input-button/radio-group-input-button.tsx';
import { RemoteComponent } from './mixer/remote-component/remote-component.tsx';
import { RequestPasswordReset } from './mixer/RequestPasswordReset/RequestPasswordReset.tsx';
import { ResetPassword } from './mixer/ResetPassword/reset-password.tsx';
import { Review } from './mixer/review/review.tsx';
import { ScoreMyCall } from './mixer/ScoreMyCall/ScoreMyCall.tsx';
import { SignInGuard } from './mixer/sign-in-guard.tsx';
import { SiteLogo } from './mixer/site-logo.tsx';
import { SitePrivacyPolicy } from './mixer/site-privacy-policy/site-privacy-policy.tsx';
import { SiteTermsOfUse } from './mixer/site-terms-of-use/site-terms-of-use.tsx';
import { StageBody } from './mixer/StageBody/StageBody.tsx';
import { StageFileInput } from './mixer/StageFileInput/StageFileInput.tsx';
import { StageName } from './mixer/StageName/StageName.tsx';
import { SubscriptionTagGuard } from './mixer/SubscriptionTagGuard/SubscriptionTagGuard.tsx';
import { Text } from './mixer/Text.tsx';
import { TextareaInput } from './mixer/TextareaInput.tsx';
import { TextInput } from './mixer/TextInput/TextInput.tsx';
import { UserProfile } from './mixer/UserProfile/UserProfile.tsx';
import { VerifiedEmailAddressGuard } from './mixer/VerifiedEmailAddressGuard/VerifiedEmailAddressGuard.tsx';
import { VerifyEmailAddress } from './mixer/VerifyEmailAddress/VerifyEmailAddress.tsx';
import { Video } from './mixer/video.tsx';
import { WidthContainer } from './mixer/width-container/width-container.tsx';

const CalendlyEmbed = lazy(() => import('./mixer/calendly-embed/calendly-embed.tsx'));
const Course = lazy(() => import('./mixer/course/course.tsx'));
const CustomizeOrganizationSite = lazy(
  () => import('./mixer/customize-org-site/customize-org-site.tsx'),
);
const FormatProps = lazy(() => import('./mixer/format-props.tsx'));
const HelpTicketsPortal = lazy(() => import('./mixer/HelpTicketsPortal/HelpTicketsPortal.tsx'));
const MultiOrgView = lazy(() => import('./mixer/multi-org-view/multi-org-view.tsx'));
const OrgSiteForms = lazy(() => import('./mixer/OrganizationSiteForms/OrganizationSiteForms.tsx'));
const OrgBillingSettings = lazy(
  () => import('./mixer/OrganizationBilling/org-billing-settings.tsx'),
);
const OrgSiteReviews = lazy(() => import('./mixer/org-site-reviews/org-site-reviews.tsx'));
const OrgMembersDashboard = lazy(
  () => import('./mixer/org-members-dashboard/OrganizationTeam.tsx'),
);
const OrderPage = lazy(() => import('./mixer/order-form/order-form.tsx'));
const ActiveOrgSiteGuard = lazy(
  () => import('./mixer/active-org-site-guard/active-org-site-guard.tsx'),
);
const OrgSitePages = lazy(() => import('./mixer/org-site-pages/org-site-pages.tsx'));
const Stage = lazy(() => import('./mixer/sequence/sequence.tsx'));
const Typeform = lazy(() => import('./mixer/Typeform/typeform.tsx'));
const ViewSequenceStagesButton = lazy(
  () => import('./mixer/ViewPlanStagesButton/ViewPlanStagesButton.tsx'),
);

const componentByType = Object.freeze({
  ActiveOrgSiteGuard,
  ActiveOrgSitePageGuard,
  AgreementsGuard,
  BooleanInput,
  Box,
  Button,
  CalendlyEmbed,
  CollapsiblePanel,
  CompletableVideoInput: CompletableVideoInput,
  ConditionalLogic,
  Course,
  CurrentYear,
  CustomizeOrganizationSite: CustomizeOrganizationSite,
  Dashboard,
  DataInputTable,
  DateInput,
  Design: CustomComponent,
  Document,
  DropdownInput,
  Form,
  FormatProps,
  FormConfirmationMessage,
  FormStep,
  HelpTicketsPortal,
  HTML,
  Icon,
  Image,
  InputValidationErrorMessage,
  LessonNotesField,
  Link,
  Map,
  MoveUserPlan: MoveSequenceUser,
  MultiOrgView,
  MultipleChoiceInput: QuizMultipleChoiceInput,
  MultiSelectInput: QuizMultiSelectInput,
  NumberInput,
  OrderPage,
  OrganizationBilling: OrgBillingSettings,
  OrganizationSiteForms: OrgSiteForms,
  OrgBillingSettings,
  OrgSitePageLink,
  OrgSitePages,
  OrganizationSiteLeads: OrganizationSiteLeads,
  OrgSiteReviews,
  OrganizationTeam: OrgMembersDashboard,
  Padding,
  Page,
  PhoneLink,
  PlainText,
  ProvideProps,
  Popover,
  RadioGroupInput,
  RadioGroupInputButton,
  RemoteComponent,
  RequestPasswordReset: RequestPasswordReset,
  ResetPassword,
  Review,
  ScoreMyCall: ScoreMyCall,
  SignInGuard,
  SiteLogo,
  SitePrivacyPolicy,
  SiteTermsOfUse,
  Stage,
  StageBody,
  StageFileInput,
  StageName,
  SubscriptionTagGuard,
  Text,
  TextInput,
  TextareaInput,
  Typeform,
  UserProfile,
  VerifiedEmailAddressGuard,
  VerifyEmailAddress,
  Video,
  ViewPlanStagesButton: ViewSequenceStagesButton,
  WidthContainer,
});

type RenderComponentProps = Component;

export const RenderComponent: FunctionComponent<RenderComponentProps> = (props) => {
  const C = componentByType[props.type] as ComponentType<Component> | undefined;
  if (C) {
    return <C {...props} />;
  }

  return null;
};
