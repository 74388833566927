import { skipToken, useSuspenseQuery } from '@wirechunk/apollo-client';
import type { DesignComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import type { FunctionComponent } from 'react';
import { use, useMemo } from 'react';
import { PropsContext } from '../../../contexts/props-context.ts';
import { ParseAndRenderComponents } from '../../ParseAndRenderComponents.tsx';
import { CustomComponentDocument } from './queries.generated.ts';

export const CustomComponent: FunctionComponent<DesignComponent> = (props) => {
  const customComponentId = props.customComponentId || props.designId;
  const { data } = useSuspenseQuery(
    CustomComponentDocument,
    customComponentId
      ? {
          variables: { id: customComponentId },
        }
      : skipToken,
  );
  const propsContext = use(PropsContext);
  const newPropsContext = useMemo<ContextData>(() => {
    if (!props.customProps) {
      return propsContext;
    }
    return {
      ...propsContext,
      ...props.customProps,
    };
  }, [props, propsContext]);

  return (
    data && (
      <PropsContext value={newPropsContext}>
        <ParseAndRenderComponents componentsJSON={data.component.components} />
      </PropsContext>
    )
  );
};
