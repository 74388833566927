import type { Component, Styles } from '@wirechunk/lib/mixer/types/components.ts';
import type { PublicSitePageQuery } from '@wirechunk/lib/shared-queries/public-site-page-query.generated.ts';
import { createContext } from 'react';

export enum ViewMode {
  // An actual page (live mode).
  Live,
  // A page template.
  Preview,
  // No page found (live mode).
  NotFound,
}

export type PageContext =
  | {
      viewMode: ViewMode.Live;
      page: NonNullable<PublicSitePageQuery['publicSite']['page']>;
    }
  | {
      viewMode: ViewMode.Preview;
      page: { title: string; components: Component[]; bodyStyles: Styles };
    }
  | { viewMode: ViewMode.NotFound };

export const defaultPageContext: PageContext = new Proxy<PageContext>({} as never, {
  get() {
    throw new Error('Page context is not initialized');
  },
});

export const PageContext = createContext<PageContext>(defaultPageContext);
