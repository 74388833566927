import type { FunctionComponent } from 'react';
import { Suspense, use } from 'react';
import { PageContext, ViewMode } from '../../contexts/page-context.tsx';
import { NotFound } from '../not-found/not-found.tsx';
import { ParseAndRenderPage } from '../parse-and-render-page.tsx';
import { RenderPreviewPage } from '../render-preview-page.tsx';
import { ErrorBoundary } from '../RootErrorBoundary/error-boundary.tsx';

export const Page: FunctionComponent = () => {
  const pageContext = use(PageContext);
  if (pageContext.viewMode === ViewMode.NotFound) {
    // No title because it is set in the router.
    return <NotFound noTitle />;
  }
  return (
    <ErrorBoundary>
      <Suspense>
        {pageContext.viewMode === ViewMode.Live ? (
          <ParseAndRenderPage
            componentsJSON={pageContext.page.components}
            bodyStylesJSON={pageContext.page.bodyStyles}
          />
        ) : (
          <RenderPreviewPage
            components={pageContext.page.components}
            bodyStyles={pageContext.page.bodyStyles}
          />
        )}
      </Suspense>
    </ErrorBoundary>
  );
};
