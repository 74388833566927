import { returnNull } from '@wirechunk/lib/const-fns.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import type { ValidationErrors } from '@wirechunk/lib/mixer/validation.ts';
import { validateData } from '@wirechunk/lib/mixer/validation.ts';
import type { DataValue, ContextData } from '@wirechunk/schemas/context-data/context-data';
import { isNil, noop } from 'lodash-es';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useCallback, use, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { PageContext, ViewMode } from './page-context.tsx';
import { usePropsContext } from './props-context.ts';
import { SiteContext } from './SiteContext/SiteContext.tsx';

type Data = {
  // Data from fields that are currently visible.
  visible: ContextData;
  // Data from fields that previously were visible but are now hidden.
  // The keys should not have any overlap with the keys in data.
  hidden?: ContextData;
};

export type InputData = {
  // A map from component name to component. While a form is mounted, this must not change referentially.
  inputComponents: Map<string, ValidInputComponent>;
  data: Data;
  // A function for setting data. While a form is mounted, this must not change referentially.
  setData: Dispatch<SetStateAction<Data>>;
  getValue: (component: Pick<ValidInputComponent, 'name'>) => DataValue;
  setValue: (component: ValidInputComponent, value: DataValue) => void;
  validate: () => ValidationErrors;
  getValidationError: (component: ValidInputComponent) => string | null;
  setValidationErrors: Dispatch<SetStateAction<ValidationErrors | null>>;
};

export const defaultValue: InputData = {
  inputComponents: new Map(),
  data: {
    visible: {},
  },
  setData: noop,
  getValue: returnNull,
  setValue: noop,
  validate: () => ({}),
  getValidationError: returnNull,
  setValidationErrors: noop,
};

export const InputDataContext = createContext<InputData>(defaultValue);

export const useInputDataContextValue = (initialData: ContextData = {}): InputData => {
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [data, setData] = useState<InputData['data']>({
    visible: initialData,
  });
  const inputComponents = useRef(new Map<string, ValidInputComponent>()).current;

  const getValidationError = useCallback<InputData['getValidationError']>(
    ({ name }) => validationErrors?.[name] || null,
    [validationErrors],
  );

  const setValue = useCallback<InputData['setValue']>(({ name }, value) => {
    setData((data) => ({
      ...data,
      visible: {
        ...data.visible,
        [name]: value,
      },
    }));
    setValidationErrors((ve) => {
      if (!ve) {
        return null;
      }
      const { [name]: _, ...rest } = ve;
      return rest;
    });
  }, []);

  return useMemo<InputData>(
    () => ({
      inputComponents,
      data,
      getValue: ({ name }) => data.visible[name],
      setData,
      setValue,
      getValidationError,
      validate: () => {
        const ve = validateData(inputComponents, data.visible);
        setValidationErrors(ve);
        return ve;
      },
      setValidationErrors,
    }),
    [inputComponents, data, setValue, getValidationError],
  );
};

export const useInputDataContext = (component: ValidInputComponent): InputData => {
  const inputDataContext = use(InputDataContext);
  const siteContext = use(SiteContext);
  const pageContext = use(PageContext);
  const propsContext = usePropsContext();

  // Note that hiddenData is relevant only to top-level input components, not for components inside data table rows.
  const { inputComponents, setData } = inputDataContext;

  // Initialize in a blocking way. In case a component is hidden and then quickly shown again, or shown and then quickly hidden,
  // we need to make sure we maintain a consistent state in inputComponents and data.
  // This hook does not have any dependencies because nothing relevant about component should change after the first render.
  useLayoutEffect(
    () => {
      // This not only checks if we already have a component with the same name but also ensures that we don't set the
      // default value anytime the props object changes.
      if (!inputComponents.has(component.name)) {
        inputComponents.set(component.name, component);
        setData((data) => {
          if (component.name in data.visible) {
            return data;
          }
          if (data.hidden && component.name in data.hidden) {
            const { [component.name]: hiddenValue, ...hidden } = data.hidden;
            return {
              visible: { ...data.visible, [component.name]: hiddenValue },
              hidden,
            };
          }
          if (component.defaultValue) {
            // Note that we never initialize with null.
            let defaultValue: DataValue = null;
            if (component.defaultValue.type === DataSource.Direct) {
              defaultValue = component.defaultValue.value;
            } else if (
              component.defaultValue.type === DataSource.Prop &&
              component.defaultValue.name
            ) {
              defaultValue = propsContext[component.defaultValue.name];
            } else if (component.defaultValue.type === 'PageTitle') {
              if (pageContext.viewMode !== ViewMode.NotFound) {
                defaultValue = pageContext.page.title;
              }
            } else if (component.defaultValue.type === 'SiteName') {
              defaultValue = siteContext.name;
            }
            if (!isNil(defaultValue)) {
              return {
                visible: { ...data.visible, [component.name]: defaultValue },
                hidden: data.hidden,
              };
            }
          }
          return data;
        });
      }

      return () => {
        setData(({ visible: { [component.name]: valueToHide, ...visible }, hidden }) => ({
          visible,
          hidden: { ...hidden, [component.name]: valueToHide },
        }));
        inputComponents.delete(component.name);
      };
    },
    [] /* eslint-disable-line react-hooks/exhaustive-deps */,
  );

  return inputDataContext;
};

type ResetInputDataContext = () => void;

// Resets the data and validation errors in the current input data context.
export const useResetInputDataContext = (): ResetInputDataContext => {
  const { setData, setValidationErrors } = use(InputDataContext);

  return useCallback(() => {
    setData({
      visible: {},
    });
    setValidationErrors(null);
  }, [setData, setValidationErrors]);
};
