import type { PublicSiteLayoutForPathQuery } from '@wirechunk/lib/shared-queries/public-site-layout-for-path-query.generated.ts';
import { PublicSiteLayoutForPathDocument } from '@wirechunk/lib/shared-queries/public-site-layout-for-path-query.generated.ts';
import type { PublicSitePageQuery } from '@wirechunk/lib/shared-queries/public-site-page-query.generated.ts';
import { PublicSitePageDocument } from '@wirechunk/lib/shared-queries/public-site-page-query.generated.ts';
import type { VisualPreviewObjectType } from '@wirechunk/lib/visual-preview.ts';
import { visualPreviewPath } from '@wirechunk/lib/visual-preview.ts';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import type { FunctionComponent } from 'react';
import { lazy, useMemo } from 'react';
import { createBrowserRouter, useLoaderData } from 'react-router';
import { apolloClient } from './apollo-client.ts';
import { CurrentSiteProvider } from './components/current-site-provider.tsx';
import { NotFound, notFoundTitle } from './components/not-found/not-found.tsx';
import { ParseAndRenderPage } from './components/parse-and-render-page.tsx';
import { ParseAndRenderComponents } from './components/ParseAndRenderComponents.tsx';
import { RethrowErrorBoundary } from './components/rethrow-error-boundary.tsx';
import { DialogProvider } from './contexts/DialogContext/DialogContext.tsx';
import { PageContext, ViewMode } from './contexts/page-context.tsx';
import { PropsContext } from './contexts/props-context.ts';
import { visualPreviewObjectType } from './initial-search-params.ts';
import { tryParseObject } from './util/json.ts';

const { admin, siteId } = window.wirechunk;

const LazyVisualPreview = lazy(() => import('./components/visual-preview/visual-preview.tsx'));

type PageRouteData = {
  page: PublicSitePageQuery['publicSite']['page'];
  layout: PublicSiteLayoutForPathQuery['publicSite']['layoutForPath'];
};

const PageRoute: FunctionComponent = () => {
  const { page, layout } = useLoaderData<PageRouteData>();
  const pageContext = useMemo<PageContext>(
    () => (page ? { viewMode: ViewMode.Live, page } : { viewMode: ViewMode.NotFound }),
    [page],
  );
  const propsContext = useMemo<ContextData>(
    () => (page?.props ? (tryParseObject(page.props) as ContextData) : {}),
    [page],
  );
  const metaTitle = page ? page.metaTitle || page.title : notFoundTitle;

  return (
    <CurrentSiteProvider>
      <title>{metaTitle}</title>
      {page?.metaDescription && <meta name="description" content={page.metaDescription} />}
      {!!page?.metaRobots.length && <meta name="robots" content={page.metaRobots.join(', ')} />}
      <PageContext value={pageContext}>
        <PropsContext value={propsContext}>
          <DialogProvider>
            {layout ? (
              <ParseAndRenderComponents
                // key is needed so that state is reset if an error boundary is hit.
                key={layout.id + (page?.id ?? '')}
                componentsJSON={layout.components}
              />
            ) : page ? (
              <ParseAndRenderPage
                // key is needed so that state is reset if an error boundary is hit.
                key={page.id}
                componentsJSON={page.components}
                bodyStylesJSON={page.bodyStyles}
              />
            ) : (
              <NotFound noTitle />
            )}
          </DialogProvider>
        </PropsContext>
      </PageContext>
    </CurrentSiteProvider>
  );
};

// All the base paths included in the admin dashboard routes.
const adminPaths = [
  '/api-tokens',
  '/create-password',
  '/dashboard',
  '/profile',
  '/reset-password',
  '/verify-email-address',
];

let dashboardPatched = false;

// The Dialog provider needs to be rendered within the RouterProvider so that anything that's rendered within the
// dialog has access to the router context. Note that toasts do not have access to the router.
export const router = createBrowserRouter(
  [
    {
      path: visualPreviewPath,
      element: (
        <CurrentSiteProvider>
          <LazyVisualPreview />
        </CurrentSiteProvider>
      ),
    },
    {
      path: '*',
      Component: PageRoute,
      loader: async ({ params }): Promise<PageRouteData> => {
        const path = params['*'] as string;
        if (
          visualPreviewObjectType === ('Page' satisfies VisualPreviewObjectType) ||
          visualPreviewObjectType === ('PageTemplate' satisfies VisualPreviewObjectType)
        ) {
          // TODO: Support overriding pages on the site with page templates to test navigating around a site.
          // TODO: Also support optionally enabling a layout (users may want to preview with or without a layout).
          return {
            layout: null,
            page: null,
          };
        }
        // This is the only way to avoid the query when the page is in the cache.
        const cachedPageData = apolloClient.readQuery<PublicSitePageQuery>({
          query: PublicSitePageDocument,
          variables: { siteId, path },
        });
        if (cachedPageData) {
          return {
            layout: cachedPageData.publicSite.page
              ? cachedPageData.publicSite.page.resolvedLayout
              : apolloClient.readQuery<PublicSiteLayoutForPathQuery>({
                  query: PublicSiteLayoutForPathDocument,
                  variables: { siteId, path },
                })?.publicSite.layoutForPath,
            page: cachedPageData.publicSite.page,
          };
        }
        const { data: pageData } = await apolloClient.query({
          query: PublicSitePageDocument,
          variables: { siteId, path },
        });
        const { page } = pageData.publicSite;
        let layout = page?.resolvedLayout;
        if (!page) {
          const { data: layoutData } = await apolloClient.query({
            query: PublicSiteLayoutForPathDocument,
            variables: { siteId, path },
          });
          layout = layoutData.publicSite.layoutForPath;
        }
        return {
          layout,
          page,
        };
      },
      ErrorBoundary: RethrowErrorBoundary,
    },
  ],
  {
    patchRoutesOnNavigation: admin
      ? async ({ path, patch }) => {
          if (!dashboardPatched && adminPaths.some((adminPath) => path.startsWith(adminPath))) {
            const { adminRoutes } = await import('./routes/admin-dashboard.tsx');
            patch(null, adminRoutes);
            dashboardPatched = true;
          }
        }
      : undefined,
  },
);
