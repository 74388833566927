import type { FormStepComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useFormContext } from '../../../contexts/FormContext/form-context.tsx';
import { ParseAndRenderComponents } from '../../ParseAndRenderComponents.tsx';
import { RenderComponentsStyled } from '../../RenderComponentsStyled.tsx';

export const FormStep: FunctionComponent<FormStepComponent> = () => {
  const form = useFormContext();

  if (!form?.currentStep) {
    return null;
  }

  return Array.isArray(form.currentStep.components) ? (
    <RenderComponentsStyled components={form.currentStep.components} />
  ) : (
    <ParseAndRenderComponents componentsJSON={form.currentStep.components} />
  );
};
