import { renderStyles } from '@wirechunk/lib/mixer/styles.ts';
import type { Component, Styles } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo } from 'react';
import { RenderComponentsStyled } from './RenderComponentsStyled.tsx';

type RenderPreviewPageProps = {
  components: Component[];
  bodyStyles: Styles;
};

export const RenderPreviewPage: FunctionComponent<RenderPreviewPageProps> = ({
  components,
  bodyStyles,
}) => {
  const bodyCss = useMemo(() => renderStyles('body', bodyStyles), [bodyStyles]);

  return (
    <Fragment>
      {bodyCss && <style>{bodyCss}</style>}
      <RenderComponentsStyled components={components} />
    </Fragment>
  );
};
